import React from "react";

const Share = ({ color }) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 512 640" fill={color}>
      <path
        d="M444,124.8c-28.7,0-52,23.3-52,52c0,1.7,0.1,3.3,0.2,5c-26.2,6.4-52.5,12.9-78.7,19.3c-7-20.4-21.9-37.2-41.1-46.7
   c1.3-3.4,2.6-6.8,4-10.2c9.4-24.2,18.8-48.3,28.1-72.5c0.8,0.1,1.6,0.1,2.4,0.1c14.9,0,27-12.1,27-27s-12.1-27-27-27s-27,12.1-27,27
   c0,8.8,4.2,16.6,10.7,21.5c-9.5,24.4-18.9,48.7-28.4,73.1c-1.2,3.2-2.5,6.4-3.7,9.6c-7.2-2-14.7-3.2-22.5-3.2
   c-22,0-41.9,8.7-56.6,22.7c-14.6-12.7-29.1-25.4-43.7-38.1c-14.5-12.6-28.9-25.2-43.4-37.8c3.6-6.1,5.7-13.2,5.7-20.8
   c0-22.6-18.4-41-41-41s-41,18.4-41,41s18.4,41,41,41c9.6,0,18.5-3.3,25.5-8.9c24.9,21.7,49.9,43.5,74.8,65.2
   c4.1,3.6,8.2,7.1,12.2,10.7c-9.8,13.5-15.5,30.1-15.5,48c0,32.1,18.5,59.9,45.4,73.4c-2.3,5.9-4.6,11.8-6.9,17.7
   c-3.4,8.7-6.8,17.4-10.2,26.2c-6.5-1.8-13.3-2.8-20.4-2.8c-42,0-76,34-76,76s34,76,76,76s76-34,76-76c0-29.6-16.9-55.3-41.7-67.8
   c3.5-8.9,6.9-17.8,10.4-26.7c2.2-5.7,4.4-11.4,6.6-17.1c7.2,2.1,14.8,3.2,22.7,3.2c14.3,0,27.8-3.7,39.5-10.1
   c12.6,18.6,25.2,37.2,37.7,55.9c0.9,1.3,1.7,2.5,2.6,3.8c-5.5,5.9-8.8,13.8-8.8,22.4c0,18.2,14.8,33,33,33s33-14.8,33-33
   s-14.8-33-33-33c-4.2,0-8.1,0.8-11.8,2.2c-13.4-19.9-26.9-39.8-40.3-59.7c18.4-15,30.1-37.9,30.1-63.5c0-4.1-0.3-8.2-0.9-12.2
   c20.6-5.1,41.3-10.1,61.9-15.2c5.6-1.4,11.2-2.7,16.8-4.1c7.7,19,26.4,32.5,48.2,32.5c28.7,0,52-23.3,52-52S472.7,124.8,444,124.8z"
      />
    </svg>
  );
};

export default Share;
